import { addDays, differenceInCalendarDays } from 'date-fns'
import isSameDay from 'date-fns/fp/isSameDay'
import { defineStore } from 'pinia'
import { SalesModesConfig } from '../config/SalesModesConfig'
import { LoadingIds } from '../constants/loadingIds'
import { NotificationDuration } from '../constants/notificationDuration'
import { SalesModes } from '../constants/salesMode'
import { StorageKeys } from '../constants/storageKeys'
import { getAnonymizedPrice } from '../devMode/salesModes'
import { makeModifiersNotification } from '../notifications/ModifiersNotifications'
import { GetRoomTypesPricesDetailsNetworkObject } from '../submodules/sharedTypes/communication/room-types/prices-detail/GetRoomTypesPricesDetailsNetworkObject'
import { GetRoomTypesPricesDetailsResponse } from '../submodules/sharedTypes/communication/room-types/prices-detail/GetRoomTypesPricesDetailsResponse'
import { UtilSales } from '../utils/UtilSales'
import { calendarConfig } from './../config/CalendarConfig'
import { DateRange } from './../submodules/sharedTypes/common/DateRange'
import { ModifierType, Modifiers } from './../submodules/sharedTypes/common/Modifiers'
import { Price, PriceSentStatus } from './../submodules/sharedTypes/common/Price'
import { GetAccommodationsPricesNetworkObject } from './../submodules/sharedTypes/communication/accommodations/prices/GetAccommodationsPricesNetworkObject'
import { GetAccommodationsPricesResponse } from './../submodules/sharedTypes/communication/accommodations/prices/GetAccommodationsPricesResponse'
import { UpdateModifiersNetworkObject } from './../submodules/sharedTypes/communication/modifiers/UpdateModifiersNetworkObject'
import { utilNetwork } from './../utils/UtilNetwork'
import { utilDate } from './../utils/utilDate'
import { useAccommodationsStore } from './accommodations'
import { useLoadingStore } from './loading'
import { useNotificationsStore } from './notifications'
import { GetAccommodationsPricesSentNetworkObject } from '~/submodules/sharedTypes/communication/accommodations/prices-sent/GetAccommodationsPricesSentNetworkObject'
import { GetAccommodationsPricesSentResponse } from '~/submodules/sharedTypes/communication/accommodations/prices-sent/GetAccommodationsPricesSentResponse'
import { PricesSentStatuses } from '../types/Prices'
import { generateDateAsIfClientIsInUTCTimezone } from '~/utils/utilParsing'

export const usePricesStore = defineStore('prices', () => {
	//const startDate = ref(new Date())
	const startDate = ref(generateDateAsIfClientIsInUTCTimezone())
	const setStartDate = (date: Date) => {
		/* console.trace(
			'dio',
			date,
			' | ',
			JSON.stringify(),
			' | ',
			JSON.stringify(date)
		) */
		startDate.value = generateDateAsIfClientIsInUTCTimezone(date)
	}

	const roomTypePrices = ref<Map<number, Price[]>>(new Map())
	const requestPrices = async (customDateRange?: Range<Date>) => {
		clearPrices()
		const loadingStore = useLoadingStore()
		await loadingStore.getPromiseDependency(LoadingIds.ACCOMMODATIONS)

		const accommodations = useAccommodationsStore().accommodationsToShow.length
			? useAccommodationsStore().accommodationsToShow
			: useAccommodationsStore().accommodations.map((element) => element.id)

		const request = new GetAccommodationsPricesNetworkObject({
			accommodationIds: accommodations,
			dateRange: customDateRange
				? customDateRange
				: {
						from: startDate.value,
						to: addDays(startDate.value, calendarConfig.maxDays - 1),
					},
		})

		utilNetwork.simpleRequest(request, undefined, LoadingIds.PRICES)
	}

	const getPriceByRoomTypeId = computed(() => {
		return (id: number) => roomTypePrices.value.get(id) || []
	})
	const getRoomTypesPricesInDateRange = computed(() => {
		return (roomTypeId: number, dateRange: DateRange) => {
			const prices = roomTypePrices.value.get(roomTypeId) || []

			/* const dateDiff = differenceInCalendarDays(dateRange.to, dateRange.from)
			return prices.slice(0, dateDiff + 1) || [] */
			return prices.filter((el) => utilDate.dateInRange(el.date, dateRange)) || []
			//return prices.filter((el) => utilDate.dateInRange(el.date, dateRange)) || []
		}
	})
	const getCurrentPricesByRoomTypeId = computed(() => {
		return (id: number) =>
			getRoomTypesPricesInDateRange.value(id, {
				from: startDate.value,
				to: addDays(startDate.value, calendarConfig.maxDays),
			})
	})

	const addAccommodationPrices = (pricesResponse: GetAccommodationsPricesResponse) => {
		const localPrices: Record<number, Price[]> = Object.fromEntries(toRaw(roomTypePrices.value.entries()))
		pricesResponse.accommodations.forEach((accommodation) => {
			accommodation.prices.forEach((price) => {
				if (UtilSales.isSalesMode.value) {
					const selectedMode = localStorage.getItem(StorageKeys.SalesMode)! as SalesModes
					const config = SalesModesConfig[selectedMode]
					price = getAnonymizedPrice(price, config)
				}

				if (!localPrices[price.roomTypeId]) {
					localPrices[price.roomTypeId] = []
				}

				if (!localPrices[price.roomTypeId]) {
					localPrices[price.roomTypeId] = toRaw(roomTypePrices.value.get(price.roomTypeId)) || []
				}

				const index = localPrices[price.roomTypeId]!.findIndex((el) => isSameDay(el.date, price.date))
				if (index == -1) {
					localPrices[price.roomTypeId].push(price)
				} else {
					localPrices[price.roomTypeId][index] = price
				}
			})
		})
		Object.keys(localPrices).forEach((key) => {
			roomTypePrices.value.set(Number(key), localPrices[Number(key)])
		})
	}
	const clearPrices = () => {
		roomTypePrices.value.clear()
	}

	const priceDetailsLoadingPriceId = ref<Set<number>>(new Set())
	const requestPriceDetails = async (price: Price) => {
		if (!priceDetailsLoadingPriceId.value.has(price.id!)) {
			const request = new GetRoomTypesPricesDetailsNetworkObject({
				roomTypeIds: [price.roomTypeId],
				dateRange: { from: price.date, to: price.date },
			})

			utilNetwork.simpleRequest(request, undefined)
			priceDetailsLoadingPriceId.value.add(price.id!)
		}
	}

	const setPriceDetails = async (res: GetRoomTypesPricesDetailsResponse) => {
		res.roomTypes.forEach((roomType) => {
			const roomPrices = roomTypePrices.value.get(roomType.id)!

			roomType.pricesDetails.forEach((priceDetails) => {
				const price = roomPrices.find((price) => price.id === priceDetails.priceId)!
				price.priceDetails = priceDetails
				priceDetailsLoadingPriceId.value.delete(priceDetails.priceId!)
			})
		})
	}

	const updateModifiers = async (date: Date, modifiers: Modifiers, roomTypeId: number) => {
		utilNetwork.simpleRequest(
			new UpdateModifiersNetworkObject({
				date,
				modifiers,
				roomTypeId,
			}),
			undefined,
			LoadingIds.PRICES
		)

		useNotificationsStore().addNotification(
			makeModifiersNotification([roomTypeId], {
				from: date,
				to: date,
			}),
			NotificationDuration.MEDIUM
		)
	}
	const getModifiersByRoomInDateRange = computed(() => {
		return (roomTypeId: number, dateRange: DateRange) => {
			const prices = getRoomTypesPricesInDateRange.value(roomTypeId, dateRange)

			return prices
				.filter((el) => utilDate.dateInRange(el.date, dateRange))
				.reduce<ModifierType[]>((map: ModifierType[], current: Price) => {
					const modifiers = current.customerParams?.modifiers || {}
					Object.keys(ModifierType).forEach((key: string) => {
						if (modifiers[key as ModifierType] != undefined && !map.includes(key as ModifierType)) {
							map.push(key as ModifierType)
						}
					})
					return map
				}, [])
		}
	})

	const pricesSentStatus = ref<PriceSentStatus>()
	const requestPricesSentStats = async () => {
		utilNetwork.simpleRequest(
			new GetAccommodationsPricesSentNetworkObject({
				accommodationIds: useAccommodationsStore().accommodations.map((element) => element.id),
			})
		)
	}
	const setPricesSentStatus = (res: GetAccommodationsPricesSentResponse) => {
		pricesSentStatus.value = res.pricesSentDetails
	}
	const getPricesStatus = computed(() => {
		if (useAccommodationsStore().accommodations.every((el) => !el.purchases.smartpricing?.priceSendEnabled))
			return PricesSentStatuses.Paused

		return pricesSentStatus.value?.areAllPricesSent ? PricesSentStatuses.Sent : PricesSentStatuses.NotSent
	})
	const getTotalPricesCount = computed(() => {
		return pricesSentStatus.value?.allPrices || 0
	})
	const getSyncingPricesCount = computed(() => {
		return pricesSentStatus.value?.notSent || 0
	})
	const getSyncedPricesCount = computed(() => {
		return pricesSentStatus.value?.successfullySent || 0
	})

	const dataThatISendToBackend = computed(() => JSON.stringify(startDate.value))

	return {
		startDate,
		setStartDate,
		dataThatISendToBackend,

		roomTypePrices,
		requestPrices,
		getPriceByRoomTypeId,
		getCurrentPricesByRoomTypeId,
		addAccommodationPrices,
		clearPrices,

		priceDetailsLoadingPriceId,
		requestPriceDetails,
		setPriceDetails,
		updateModifiers,
		getModifiersByRoomInDateRange,

		pricesSentStatus,
		requestPricesSentStats,
		setPricesSentStatus,
		getPricesStatus,
		getTotalPricesCount,
		getSyncingPricesCount,
		getSyncedPricesCount,
	}
})
